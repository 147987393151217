<template>
	<div>
        <rxNavBar title="变更申请"></rxNavBar>
        <div id="paymentMethodPanel" class="part-inputpart-row" @click="toSearch" >
            <span :class="proposer.applyStaff ? 'content-have' : 'content-none'"></span>
            <span class="part-inputpart-row-header" style="font-size: 14px">申请人</span>
            <span class="content-divide">|</span>
            <span  class="part-inputpart-row-normaltext" style="font-size: 12px">{{ proposer.applyName }}</span>
            <img class="part-inputpart-row-right" :class="proposer.applyStaff? 'part-inputpart-row-right-upArrow': 'part-inputpart-row-right-downArrow'" src="../../assets/images/triangle.png"/>
        </div>
        <div id="paymentMethodPanel" class="part-inputpart-row" @click="getApplyReasonList" >
            <span :class="proposer.applyReason ? 'content-have' : 'content-none'"></span>
            <span class="part-inputpart-row-header" style="font-size: 14px">申请原因</span>
            <span class="content-divide">|</span>
            <span  class="part-inputpart-row-normaltext" style="font-size: 12px">{{ proposer.applyReasonName }}</span>
            <img class="part-inputpart-row-right" :class="proposer.applyReason? 'part-inputpart-row-right-upArrow': 'part-inputpart-row-right-downArrow'" src="../../assets/images/triangle.png"/>
        </div>
        <van-popup v-model="applyReasonShow" position="bottom">
            <van-picker
              show-toolbar
              value-key="dictionaryTitle"
              :columns="reasonForApplicationList"
              @cancel="applyReasonShow = false"
              @confirm="confirmApplyReason"
            />
        </van-popup>
        <div class="addPropertyDetail">
            <textarea class="textareaRemarks" placeholder="备注" v-model="proposer.applyMemo"></textarea>
        </div>
        <div class="potential">
            <div class="panels">
                <van-row class="info" type="flex">
                    <van-col span="24" >
                        <p style="float:right;color:#ff5d3b;padding-right:10px;font-size:15px" @click="addProject">添加条目<span></span></p>
                    </van-col>
                </van-row>
            </div>
        </div>
        <van-popup class="van-popup--bottom" v-model="applicationShow" position="bottom">
          <!--<div  class="part-inputpart-row" @click="getBigProgramList" >
            <span :class="application.goodsAllocationClassId ? 'content-have' : 'content-none'"></span>
            <span class="part-inputpart-row-header">大项</span>
            <span class="content-divide">|</span>
            <span  class="part-inputpart-row-normaltext">{{ application.goodsAllocationClassName }}</span>
            <img class="part-inputpart-row-right" :class="application.goodsAllocationClassId ? 'part-inputpart-row-right-upArrow': 'part-inputpart-row-right-downArrow'" src="../../assets/images/triangle.png"/>
          </div>
          <div  class="part-inputpart-row" @click="getCenterProgramList" >
            <span :class="application.goodsallocationmiddlesumId ? 'content-have' : 'content-none'"></span>
            <span class="part-inputpart-row-header">软装</span>
            <span class="content-divide">|</span>
            <span  class="part-inputpart-row-normaltext">{{ application.middlesumName }}</span>
            <img class="part-inputpart-row-right" :class="application.goodsallocationmiddlesumId? 'part-inputpart-row-right-upArrow': 'part-inputpart-row-right-downArrow'" src="../../assets/images/triangle.png"/>
          </div>-->
          <div  class="part-inputpart-row" @click="getRoomList" >
            <span :class="application.roomId ? 'content-have' : 'content-none'"></span>
            <span class="part-inputpart-row-header" style="font-size: 14px;">位置</span>
            <span class="content-divide">|</span>
            <span  class="part-inputpart-row-normaltext" style="font-size: 12px;">{{ application.roomName }}</span>
            <img class="part-inputpart-row-right" :class="application.roomId? 'part-inputpart-row-right-upArrow': 'part-inputpart-row-right-downArrow'" src="../../assets/images/triangle.png"/>
          </div>
          <div  class="part-inputpart-row" @click="clickArea">
                <div :class="application.regionalLocation ? 'content-have' : 'content-none'"></div>
                <span class="part-inputpart-row-header" style="font-size: 14px;">区域</span>
                <span class="content-divide">|</span>
                <span class='part-inputpart-row-normaltext' @change="getAreaList" style="font-size: 12px;">{{ application.regionalLocationValue }}</span>
                <img class="part-inputpart-row-right" :class="application.regionalLocation? 'part-inputpart-row-right-upArrow': 'part-inputpart-row-right-downArrow'" src="../../assets/images/triangle.png"/>
            </div>
          <div  class="part-inputpart-row" @click="getSmallProgramList" >
            <span :class="application.item_id ? 'content-have' : 'content-none'"></span>
            <span class="part-inputpart-row-header" style="font-size: 14px;">物品名称</span>
            <span class="content-divide">|</span>
            <span  class="part-inputpart-row-normaltext" style="font-size: 12px;">{{ application.itemName }}</span>
            <img class="part-inputpart-row-right" :class="application.item_id? 'part-inputpart-row-right-upArrow': 'part-inputpart-row-right-downArrow'" src="../../assets/images/triangle.png"/>

          </div>
          <div style="margin-left: 110px;font-size: 12px;color: red" v-if="application.itemName&&itemType==1">
            <span>库存剩余{{conent}}个</span>
          </div>
          <div  class="part-inputpart-row">
            <div :class="application.count ? 'content-have' : 'content-none'"></div>
            <span class="part-inputpart-row-header" style="font-size: 14px;">数量</span>
            <span class="content-divide">|</span>
              <div class="nopadding">
                <van-field style="font-size: 12px;" class="nopadding" :formatter="formatter" type="digit" v-model="application.count" placeholder="数量" @input="changeMoney()" :disabled="notEditable"></van-field>
              </div>
          </div>
          <div class="part-inputpart-row">
              <div :class="application.estimateMoney ? 'content-have' : 'content-none'"></div>
              <span class="part-inputpart-row-header" style="font-size: 14px;">预计</span>
              <span class="content-divide">|</span>
              <div class="nopadding">
                <van-field class="nopadding" style="font-size: 12px;" :formatter="formatter" type="digit" v-model="application.estimateMoney" placeholder="预计" disabled ></van-field>
              </div>
          </div>
          <div class="part-inputpart-row">
              <div :class="application.money ? 'content-have' : 'content-none'"></div>
              <span class="part-inputpart-row-header" style="font-size: 14px;">实际</span>
              <span class="content-divide">|</span>
              <div class="nopadding">
                <van-field class="nopadding" style="font-size: 12px;" :formatter="formatter" type="digit" @input="getMoney()"  v-model="application.money" placeholder="金额" ></van-field>
              </div>
          </div>
          <div id="paymentMethodPanel" class="part-inputpart-row" @click="getSupplierList" v-if="itemType=='1'">
            <span :class="application.supplier_id ? 'content-have' : 'content-none'"></span>
            <span class="part-inputpart-row-header" style="font-size: 14px;">供应商</span>
            <span class="content-divide">|</span>
            <span  class="part-inputpart-row-normaltext" style="font-size: 12px;">{{ application.supplierName }}</span>
            <img class="part-inputpart-row-right" :class="application.supplier_id? 'part-inputpart-row-right-upArrow': 'part-inputpart-row-right-downArrow'" src="../../assets/images/triangle.png"/>
          </div>
          <div id="paymentMethodPanel" class="part-inputpart-row" @click="clickAddReason" v-if="itemType=='1'&&(parseInt(application.count?application.count:0)+haveAddCount>application.roomHaveSum||application.reasonAddition)">
            <span :class="application.reasonAddition ? 'content-have' : 'content-none'"></span>
            <span class="part-inputpart-row-header" style="font-size: 14px;">加项原因 </span>
            <span class="content-divide">|</span>
            <span  class="part-inputpart-row-normaltext" style="font-size: 12px;">{{ application.reasonName }}</span>
            <img class="part-inputpart-row-right" :class="application.reasonAddition? 'part-inputpart-row-right-upArrow': 'part-inputpart-row-right-downArrow'" src="../../assets/images/triangle.png"/>
          </div>
          <div class="part-inputpart-row-vedio" v-if="itemType=='1'&&(parseInt(application.count?application.count:0)+haveAddCount>application.roomHaveSum||application.reasonAddition)">
                <div :class="application.picList.length>0 ? 'content-have' : 'content-none'"  style="position:absolute;top:14px"></div>
                <div style="position:absolute;left:45px;top:8px;font-weight:900;font-size:14px;" >加项证据</div>
                <div class="upLoadImg" >
                    <van-uploader v-model="application.picList" multiple
                                  accept="image/*"
                                  preview-size="80px"
                                  :after-read="afterReadTest"
                                  style="padding-left: 0.25rem"/>
                </div>
        </div>
          <div class="addPropertyDetail">
            <textarea class="textareaRemarks" placeholder="备注" v-model="application.remarks"></textarea>
          </div>
        <van-popup v-model="bigProgramShow" position="bottom">
            <van-picker
              show-toolbar
              value-key="name"
              :columns="bigProgramList"
              @cancel="bigProgramShow = false"
              @confirm="confirmBigProgram"
            />
        </van-popup>
        <!--<van-popup v-model="centerProgramShow" position="bottom">
            <van-picker
              show-toolbar
              value-key="middlesumName"
              :columns="centerProgramList"
              @cancel="centerProgramShow = false"
              @confirm="confirmCenterProgram"
            />
        </van-popup> -->
        <van-popup v-model="areaShow" position="bottom">
            <van-picker
              show-toolbar
              value-key="regionalLocationValue"
              :columns="areaList"
              @cancel="areaShow = false"
              @confirm="confirmArea"
            />
        </van-popup>
        <van-popup v-model="smallProgramShow" position="bottom">
            <van-picker
              show-toolbar
              value-key="itemName"
              :columns="smallProgramList"
              @cancel="smallProgramShow = false"
              @confirm="confirmSmallProgram"
            />
        </van-popup>
        <van-popup v-model="roomShow" position="bottom">
            <van-picker
              show-toolbar
              value-key="roomName"
              :columns="roomList"
              @cancel="roomShow = false"
              @confirm="confirmRoom"
            />
        </van-popup>
        <van-popup v-model="supplierShow" position="bottom">
            <van-picker
              show-toolbar
              value-key="supplierName"
              :columns="supplierList"
              @cancel="supplierShow = false"
              @confirm="confirmSupplier"
            />
        </van-popup>
        <van-popup v-model="addreasonShow" position="bottom">
            <van-picker
              show-toolbar
              value-key="dictionaryTitle"
              :columns="addreasonList"
              @cancel="addreasonShow = false"
              @confirm="confirmAddReason"
            />
        </van-popup>
          <div style="width: 100%;padding: 15px">
        <van-button class="Button_Enable" :disabled="saveingStatus"
                @click="readySave">保存
        </van-button>
            <van-button class="closeButton_Enable" :disabled="saveingStatus"
                        @click="close">取消
            </van-button>
          </div>
        </van-popup>

        <div class="contracts">
            <van-pull-refresh v-model="refreshing"  @refresh="onRefresh">
                <van-list
                        v-model="loading"
                        :finished="finished"
                        :finished-text="noContent ? '' : '已经到底了'"
                        @load="this.totalBatchAlertApplication"
                >
                    <!--合同列表-->
                    <div class="contract" v-for="(item,index) in totalChangeApplicationList" :key="index">
                        <div :class="item.roomName?'top-title-green':'top-title-red'">{{item.roomName}}间</div>
                        <div class="position" >{{item.itemName}}</div>
                        <!--<div class="contract-info" >
                            <div class="contract-text">预计金额：{{item.estimateMoney}}</div>
                            <div class="contract-text">实际金额：{{item.money?item.money:item.realMoney}}</div>
                            <div class="contract-text">数量：{{item.totalCount}}</div>

                            <div class="contract-text">供应商：{{item.supplierName}}</div>
                             <div class="contract-text">加项原因：{{item.reasonAdditionTitle}}</div>
                            <div class="contract-textLong">备注：{{item.remarks}}</div>
                        </div>-->
                <div class="potential">
                    <div class="panel">
                        <van-row class="info">
                            <van-col span="24">
                                <p>区域：<span>{{ item.regionalLocationName?item.regionalLocationName:item.roomName }}</span></p>
                            </van-col>
                            <van-col span="12">
                                <p>预计金额：<span>{{ item.estimateMoney }}</span></p>
                            </van-col>
                            <van-col span="12">
                                <p>实际金额：<span>{{ item.money?item.money:item.realMoney }}</span></p>
                            </van-col>
                            <van-col span="12">
                                <p>数量：<span>{{ item.totalCount?item.totalCount:item.count }}</span></p>
                            </van-col>
                            <van-col span="12" v-if="item.supplierName">
                                <p>供应商：<span>{{ item.supplierName }}</span></p>
                            </van-col>
                            <van-col span="12" v-if="item.reasonAdditionTitle?item.reasonAdditionTitle:item.reasonName">
                                <p>加项原因：<span>{{ item.reasonAdditionTitle?item.reasonAdditionTitle:item.reasonName }}</span></p>
                            </van-col>
                            <van-col span="24">
                                <p>备注：<span>{{ item.remarks }}</span></p>
                            </van-col>
                        </van-row>
                    </div>
                </div>
                        <div class="bottom">
                            <div @click="changeApplication(item,index)" class="bottom-changeLease" v-if="item.status!='2'">修改</div>
                            <div @click="deleteApplication(item,index)" class="bottom-property" style="float:left" v-if="item.status!='2'">删除</div>
                        </div>

                    </div>
                    <common-empty v-if="noContent"></common-empty>
                </van-list>
            </van-pull-refresh>
        </div>

<!--<van-tabbar v-model="active">-->
  <div style="width: 100%;padding: 15px;position: fixed;bottom: 0;background-color: #ffffff">
    <van-button class="Button_Enable" :disabled="saveingStatus"
                @click="save">保存
    </van-button>
<!--  <van-tabbar-item name="home" >-->
<!--    <div class="saveButton_Enable" @click="save">保存</div>-->
<!--  </van-tabbar-item>-->
  </div>
<!--</van-tabbar>-->
        <!--<van-button class="saveButton_Enable" :disabled="saveingStatus"
                @click="save">保存
        </van-button> -->
		<div style="width: 100%;height: 50px;"></div>
	</div>
</template>

<script>
	import {
		NavBar,
		Picker,
		List,
		PullRefresh,
		Toast,
		Popup,
        Row,
        Col,
        Field,
        Button,
        Uploader,
        Dialog,
        Tabbar,TabbarItem,
	} from "vant";
	Vue.use(Field);
	Vue.use(Picker);
	Vue.use(List);
	Vue.use(PullRefresh);
	Vue.use(Popup);
  import {
    addGoodsallocation,
    querySupplierList,
    queryDictionary,
    base64Upload,
    goodClassquerygood,
    middlesumquerygood,
    querygoodsconfigure,
    queryHardcoverSoftOutfit,
    queryStaffList,
    getQueryBaseData,
    distributionitemChange,
    queryRoom,
    findHouseArea,
    queryGoodsCount
  } from "../../getData/getData";
	import {
		 getStaffId,
		getUserId,
		globaluserId,
		responseUtil,
        dealImage,
	} from "../../libs/rongxunUtil";
	import rxNavBar from "../../components/rongxun/rx-navBar/navBar";
	import Vue from 'vue';
	import dialMobile from '../../components/rongxun/rx-dialMobile/dialMobile'
    import eventBus from "../../components/rongxun/rx-navBar/envbus.js"
	export default {
		name: "decorat",
		data() {
			return {
        conent:'',
                applyReasonShow:false,
                applicationShow:false,
                itemType:'',     //0为硬装,1为软装
                bigProgramList:[],    //大项
                bigProgramShow:false,    //弹出大项列表
                centerProgramList:[],
                centerProgramShow:false,
                smallProgramList:[],
                smallProgramShow:false,
                areaShow:false,
                roomList:[],          //区间
                roomShow:false,
                submitAlertApplyData:[],    //提交的数据
                noContent:false,
                loading: false,
                finished: false,
                refreshing:false,
                rtid:'',    //装修id
                totalChangeApplicationList:[],    //列表
                staffList:[],                     //申请人
                reasonForApplicationList:[],      //申请原因

                supplierShow:false,    //供应商列表弹出/隐藏
                supplierList:[],    //供应商列表
                application:{
                    estimateMoney:0,
                    picList:[],
                },
                unitPrice:0,    //装修项单价
                addreasonList:[],       //加项原因集合
                addreasonShow:false,    //加项原因列表弹出/隐藏
                saveingStatus: false,   //控制保存按钮状态，防止二次点击  默认为保存，可点击
                active:0 ,
                notEditable:false,     //false可编辑，true不可编辑
                haveAddCount:0,        //中项已存在的数量
                price:'',
                proposer:{},
                chooseIndex:'',
                hardPage:{currentPage:1,numberPage:3},
                softPage:{currentPage:1,numberPage:3},
                areaList:[],
			}
		},
		components: {
			[NavBar.name]: NavBar,
			[List.name]: List,
            [Button.name]: Button,
			[PullRefresh.name]: PullRefresh,
            [Uploader .name]:Uploader,
            [Row.name]: Row,
            [Col.name]: Col,
			dialMobile,
			rxNavBar,
            [Dialog.Component.name]: Dialog.Component,
            [Tabbar.name]: Tabbar,
            [TabbarItem.name]: TabbarItem,
		},
		created() {
            this.itemType=this.$route.query.itemType
            this.bigProgramList=this.$route.query.program
            //this.roomList=this.$route.query.roomList
            this.rtid=this.$route.query.rtid
            this.arid=this.$route.query.arid
            this.initStaffListData()
            this.initDictionaryData()
            this.getQueryDictionary()
            if(this.itemType=='1'){
               this.getQuerySupplierList()
            }
            this.getQueryRoom()
		},
		mounted() {
            // this.getAreaList()

		},
		methods: {

      queryGoodsCount(e){
        let that=this
        let initData={}
        initData.tgId = e
        initData.itemType=this.$route.query.decorationType
        queryGoodsCount(initData).then(function (response) {
          responseUtil.dealResponse(that, response, () => {
            let conent = 0
            let quantity = 0
            let issueInTransit = 0
            if(response.data.data.data.length == 0){
              that.conent = 0
            }else{
              console.log(1222)
              for (let i = 0; i < response.data.data.data.length; i++) {
                quantity += response.data.data.data[i].quantity
                issueInTransit += response.data.data.data[i].issueInTransit
              }
              conent = quantity-issueInTransit
              that.conent = conent
            }
            console.log(quantity)
            console.log(issueInTransit)
            console.log(conent)


          })
        })
      },
            //获取间和公区
            getQueryRoom(){
                let that=this
                let initData={}
                initData.sethouse_id=that.$route.query.setHouse_id
                initData.ar_id=that.arid
                queryRoom(initData).then(function (response) {
                  responseUtil.dealResponse(that, response, () => {
                    that.roomList=response.data.data.data

                  })
                })
            },
            toSearch(){
                this.$router.push({
                        name:'referrer',
                        query:{
                            type:'1',
                            titleName:'申请人'
                    }
                })
            },
            //根据大项获取中项
            getCenterProgram(e){
                if(!e||!this.application.roomId){
                    return
                }
                let that=this
                let initData={}
                initData.id=e.id
                initData.afterRent_id=this.arid
                initData.roomhouse_id=this.application.roomId
                goodClassquerygood(initData).then(function (response) {
                    responseUtil.dealResponse(that, response, () => {
                        that.centerProgramList=response.data.data.data
                    })
                })
            },
            //根据中项获取小项
            getSmallProgram(e){
                if(!e){
                    return
                }
                /*for(let i in this.centerProgramList){
                    if(e==this.centerProgramList[i].id){
                        this.roomHaveSum=this.centerProgramList[i].roomHaveSum
                        break
                    }
                }*/
                let that=this
                let initData={}
                initData.id=e.id
                middlesumquerygood(initData).then(function (response) {
                    responseUtil.dealResponse(that, response, () => {
                        that.smallProgramList=response.data.data.data
                    })
                })
            },
            getApplyReasonList(){
                this.applyReasonShow=true
            },
            //选择申请原因
            confirmApplyReason(e){
                this.proposer.applyReason=e.id
                this.proposer.applyReasonName=e.dictionaryTitle
                this.applyReasonShow=false
            },
            //弹出大项列表
            getBigProgramList(){
                if(this.notEditable){
                   return
                }
                this.bigProgramShow=true
            },
            //确认大项
            confirmBigProgram(e){
                this.application.goodsAllocationClassId=e.id
                this.application.goodsAllocationClassName=e.name
                this.bigProgramShow=false
                this.getCenterProgram(e)
            },
            //弹出中项列表
            getCenterProgramList(){
                if(this.notEditable){
                   return
                }
                this.centerProgramShow=true
            },
            //确认中项
            /*confirmCenterProgram(e){
                this.application.roomHaveSum=e.roomHaveSum
                this.haveAddCount=e.existingQuantity?e.existingQuantity:0
                this.application.goodsallocationmiddlesumId=e.id
                this.application.middlesumName=e.middlesumName
                this.centerProgramShow=false
                this.getHaveAddCount(e.id)
                this.getSmallProgram(e)
            },*/
            getHaveAddCount(id){
                console.log(this.submitAlertApplyData)
                for(let i in this.submitAlertApplyData){
                    if(this.application.roomId==this.submitAlertApplyData[i].roomId&&id==this.submitAlertApplyData[i].goodsallocationmiddlesumId&&this.submitAlertApplyData[i].type=='0'&&this.submitAlertApplyData[i].itemType=='1'){
                       this.haveAddCount=this.haveAddCount+Number(this.submitAlertApplyData[i].count)
                    }else if(this.application.roomId==this.submitAlertApplyData[i].roomId&&id==this.submitAlertApplyData[i].goodsallocationmiddlesumId&&this.submitAlertApplyData[i].type=='1'&&this.submitAlertApplyData[i].itemType=='1'){
                        this.haveAddCount=this.haveAddCount-Number(this.submitAlertApplyData[i].count)
                    }
                }
            },
            //弹出小项列表
            getSmallProgramList(){
                if(this.notEditable){
                   return
                }
                this.$router.push({
                        name:'decorateSearch',
                        query:{
                            //id:this.$route.query.id,
                            sethouse_id:this.$route.query.setHouse_id,
                            arid:this.$route.query.arid,
                            decorationType:this.itemType,
                            roomHouse_id:this.application.roomId,
                            type:'changeApplication'
                    }
                })
                //this.smallProgramShow=true
            },
            //确认小项
            confirmSmallProgram(e){
                this.price=e.price
                this.application.item_id=e.id
                this.application.itemName=e.itemName
                this.smallProgramShow=false
            },
            //弹出区间列表
            getRoomList(){
                if(this.notEditable){
                   return
                }
                this.roomShow=true
            },
            //确认区间
            confirmRoom(e){
                if(this.application.roomName!=e.roomName){
                    this.application.regionalLocationName=''
                }
                this.application.roomId=e.id
                this.application.roomName=e.roomName
                this.application.itemName=''
                this.application.item_id=''
                this.roomShow=false
            },
            //弹出供应商列表
            getSupplierList(){
                if(this.notEditable){
                   return
                }
                this.supplierShow=true
            },
            //确认供应商
            confirmSupplier(e){
                this.application.supplier_id=e.id
                this.application.supplierName=e.supplierName
                this.supplierShow=false
            },
            //供应商列表
            getQuerySupplierList(){
                let that=this
                let initData={}
                querySupplierList(initData).then(function (response) {
                    responseUtil.dealResponse(that, response, () => {
                        that.supplierList=response.data.data.data
                    })
                })
            },

            //点击区域，弹出下拉框
             clickArea(){
                this.areaShow = !this.areaShow
                this.getAreaList()
            },
            //查询区域列表
            getAreaList(){
                let that = this
                let initData = {}
                initData.setHouse_id = this.$route.query.setHouse_id
                if(that.application.roomId!='-1'){
                    initData.roomHouse_id = that.application.roomId
                }
                findHouseArea(initData).then(function (response) {
                    responseUtil.dealResponse(that, response, () => {
                        that.areaList=response.data.data.data
                    })
                })
            },
            //确认
            confirmArea(item){
                console.log(item)
                this.application.regionalLocationId=item.id
                this.application.regionalLocation=item.id
                this.application.regionalLocationValue=item.regionalLocationValue
                this.areaShow=false
            },
            //要保存的数据
            readySave(){
                if(!this.saveAble()){
                    responseUtil.alertMsg(this,"必填项没填")
                     return
                }
                if(!this.application.type){
                    this.application.type='0'
                    this.application.status='readyToAdd'
                }
                this.application.itemType=this.itemType
                if(this.application.type=='0'){  //硬装
                    this.submitAlertApplyData.unshift(this.application)
                    this.countNewList('0')
                }else if(this.application.type=='2'){  //软装
                    let haveSame=false
                    for(let i in this.submitAlertApplyData){
                        if(this.application.id==this.submitAlertApplyData[i].id){
                            haveSame=true
                           this.submitAlertApplyData.splice(i,1,this.application)
                           break
                        }
                    }
                    if(!haveSame){
                        this.submitAlertApplyData.push(this.application)
                    }
                    this.countNewList('2')
                }
                this.price=0
                this.application={estimateMoney:0,picList:[]}
                this.applicationShow=false
            },
      close(){
        this.applicationShow=false
      },
            //删除
            deleteApplication(e,index){
                if(!this.haveApplicationPersonAndReason()){
                    responseUtil.alertMsg(this,"请选择申请人和申请原因")
                    return
                }
                if(e.status=='readyToAdd'){
                    for(let i in this.submitAlertApplyData){
                        if(e.roomId==this.submitAlertApplyData[i].roomId&&e.goodsallocationmiddlesumId==this.submitAlertApplyData[i].goodsallocationmiddlesumId){
                            this.submitAlertApplyData.splice(i,1)
                            break
                        }
                    }
                    for(let i in this.totalChangeApplicationList){
                        if(e.roomId==this.totalChangeApplicationList[i].roomId&&e.goodsallocationmiddlesumId==this.totalChangeApplicationList[i].goodsallocationmiddlesumId){
                            this.totalChangeApplicationList.splice(i,1)
                            break
                        }
                    }
                    //this.submitAlertApplyData.splice(index,1)
                    //this.totalChangeApplicationList.splice(index,1)
                    return
                }else{
                    /*if(this.itemType=='1'&&!e.renovationorderreceiveitemId){
                        responseUtil.alertMsg(this,'未接单,不可删除')
                        return
                    }*/
                }
                Dialog.confirm({
                    title: '友情提示',
                    message: '确定删除吗？',
                })
                .then(() => {
                    let json={}
                    json.count=e.totalCount
                    json.type='1'
                    json.roomId=e.roomHouseId
					json.money=e.realMoney?e.realMoney:e.estimateMoney
					json.remarks=e.remarks
					json.item_id=e.item_id
					json.supplier_id=e.supplier_id
                    json.itemType=this.itemType
                    json.reasonAddition=e.reasonAddition
                    json.renovationorderreceiveitemId=e.renovationorderreceiveitemId
                    json.renovationdistributionitem_id=e.id
                    json.picList=e.picList
                    json.goodsallocationmiddlesumId=e.goodsallocationmiddlesumId
                    json.haveAlertCount=0
                    this.submitAlertApplyData.push(json)
                    this.chooseIndex=index
                    this.haveAddCount=0
                    this.countNewList('1')
                    this.getHaveAddCount(e.goodsallocationmiddlesumId)
               })
          .catch(() => {
          });
            },
            //修改
            changeApplication(e,index){
                console.log(e)
                if(!this.haveApplicationPersonAndReason()){
                    responseUtil.alertMsg(this,"请选择申请人和申请原因")
                    return
                }
                this.haveAddCount=0
               this.notEditable=true
               this.applicationShow=true
               this.application={
                   goodsAllocationClassId:e.goodsAllocationClassId,
                   goodsAllocationClassName:e.goodsAllocationClassName,
                   goodsallocationmiddlesumId:e.goodsallocationmiddlesumId,
                   middlesumName:e.middlesumName,
                   item_id:e.item_id,
                   itemName:e.itemName,
                   roomId:e.roomId?e.roomId:e.roomHouseId,
                   roomName:e.roomName,
                   regionalLocation : e.regionalLocation,
                   regionalLocationName : e.regionalLocationName,
                   remarks:e.remarks,
                   estimateMoney:e.estimateMoney,
                   money:e.money?e.money:e.realMoney,
                   count:e.count?e.count:e.totalCount,
                   totalCount:e.count?e.count:e.totalCount,
                   type:'2',
                   supplier_id:e.supplier_id,
                   supplierName:e.supplierName,
                   reasonAddition:e.reasonAddition,
                   reasonName:e.reasonName?e.reasonName:e.dictionaryTitle,
                   renovationdistributionitem_id:e.renovationdistributionitem_id?e.renovationdistributionitem_id:e.id,
                   picList:e.picList?e.picList:[],
                   id:e.id,
                   roomHaveSum:e.roomHaveSum,
                   status:e.status,
                   regionalLocationValue:e.regionalLocationValue
               }
               this.chooseIndex=index
            },
            //根据增删改计算新的列表
            countNewList(type){

                if(type=='0'){  //增加
                    this.totalChangeApplicationList.unshift(this.application)
                }else if(type=='1'){  //删除
                    this.totalChangeApplicationList.splice(this.chooseIndex,1)
                }else if(type=='2'){  //修改
                    this.totalChangeApplicationList.splice(this.chooseIndex,1,this.application)
                }
                console.log(this.submitAlertApplyData)
                console.log(this.totalChangeApplicationList)
            },
            //变更申请列表
            totalBatchAlertApplication(){
          let that=this
          let initData={}
          initData.user_id = getStaffId()
          initData.rtid = that.rtid
          initData.hardCurrentPage=that.hardPage.currentPage
          initData.hardPageSize=that.hardPage.numberPage
          initData.softCurrentPage=that.softPage.currentPage
          initData.softPageSize=that.softPage.numberPage
          queryHardcoverSoftOutfit(initData).then(function (response) {
              responseUtil.dealResponse(that, response, () => {
                  let data=''
                  if(that.$route.query.itemType=='0'){
                      data=response.data.data.hardMapList
                        if(data){
                            for(let i=0;i<data.length;i++){
                                that.totalChangeApplicationList.push(data[i])
                            }
                        }
                        that.loading=false
                        if (that.totalChangeApplicationList.length >=response.data.data.pageHardCount) {
                            that.finished = true;
                        }
                        that.hardPage.currentPage++
                      //that.totalChangeApplicationList=response.data.data.hardMapList
                  }else if(that.$route.query.itemType=='1'){
                      data=response.data.data.softMapList
                        if(data){
                            for(let i=0;i<data.length;i++){
                                that.totalChangeApplicationList.push(data[i])
                            }
                        }
                        that.loading=false
                        if (that.totalChangeApplicationList.length >=response.data.data.pageSoftCount) {
                            that.finished = true;
                        }
                        that.softPage.currentPage++
                      //that.totalChangeApplicationList=response.data.data.softMapList
                  }
        })
      })

            },
            //根据数量改变价格
            changeMoney(){
                if(!this.price){
                    return
                }
                console.log(this.application.money)
                this.application.money=Number(this.application.count*this.price)
            },
            getMoney(){
                this.application.money=this.application.money
                this.$forceUpdate()
            },
            // 获取员工数据
            initStaffListData: function () {
                var that = this
                let initData = {}
                initData.user_id = getStaffId()
                queryStaffList(initData).then(function (response) {
                    responseUtil.dealResponse(that, response, () => {
                        that.staffList = response.data.data
                    })
                })
            },
            // 获取字典数据
            initDictionaryData: function () {
                var that = this
                let initData = {}
                initData.user_id = getStaffId()
                initData.dbName = ['reasonForApplication']
                getQueryBaseData(initData).then(function (response) {
                    responseUtil.dealResponse(that, response, () => {
                        that.reasonForApplicationList=response.data.data.reasonForApplication
                    })
                })
            },
    //物品配置图片多选上传
            afterReadTest(file){
                // this.loadingFlag = true;
                let fileLength = parseInt(file.length) - parseInt(1)
                if(file.length > 1){
                    for(let i = 0; i<file.length;i++){
                        let fileDetail = file[i];
                        dealImage(fileDetail,fileDetail.content,400,this.afterRead,i,fileLength);
                    }
                }else{
                    dealImage(file,file.content,400,this.afterRead);
                }
            },

    afterRead(file,i,fileLength) {
      let that = this
      let initData = {
        base64 : file.content
      }
      that.loadingFlag = true;
      base64Upload(initData).then(function (response) {
        that.loadingFlag = false;
        responseUtil.dealResponse(that, response, () => {
          file.url = response.data.data.path
          for(let i in that.application.picList){
              that.application.picList[i].path=that.application.picList[i].url
          }
          if(i == fileLength){
            that.loadingFlag = false
          }
        })
      })
    },
            onRefresh() {
                // 清空列表数据
                this.finished = false;
                this.totalChangeApplicationList = []
                this.hardPage.currentPage = 1
                this.softPage.currentPage = 1
                this.loading = true;
                this.refreshing = false;
                //this.onLoad();
                responseUtil.alertMsg(this,"刷新成功")
            },

            clickAddReason(){
                if(this.notEditable){
                   return
                }
                this.addreasonShow=!this.addreasonShow
            },
            confirmAddReason(item){
               this.application.reasonAddition=item.id
               this.application.reasonName=item.dictionaryTitle
               this.addreasonShow=false
            },
			addProject(){
                if(!this.haveApplicationPersonAndReason()){
                    responseUtil.alertMsg(this,"请选择申请人和申请原因")
                    return
                }
                this.haveAddCount=0
                this.notEditable=false
                this.application={estimateMoney:0,picList:[]}
              this.applicationShow=true
            },
            //确认填写了申请人，申请原因
            haveApplicationPersonAndReason(){
               if(this.proposer.applyStaff&&this.proposer.applyReason){
                   return true
               }
               return false
            },
            saveAble() {
                let that=this
                if (that.application.itemName&&
                that.application.count>0&&
                (that.itemType=='0'||that.application.supplier_id)&&
                (((!that.notEditable&&that.itemType=='1'&&parseInt(that.application.count)+that.haveAddCount>that.application.roomHaveSum&&that.application.reasonAddition&&that.application.picList.length>0)||
                that.notEditable)||
                parseInt(that.application.count)+that.haveAddCount<=that.application.roomHaveSum||
                that.itemType=='0')){
                    console.log(that.itemType)
                    console.log(that.application.supplier_id)
                    return true
                }else{
                    return false
                }
            },
            save(){
                let that=this
                let initData={}
                initData.afterRent_id=that.arid
                initData.renovation_id=that.rtid
                initData.user_id=getStaffId()
                initData.applyMemo=that.proposer.applyMemo
                initData.applyReason=that.proposer.applyReason
                initData.applyStaff=that.proposer.applyStaff
                initData.softList=that.submitAlertApplyData
                initData.itemType=that.itemType
                console.log(initData)
                distributionitemChange(initData).then(function (response) {
                    responseUtil.dealResponse(that, response, () => {
                    responseUtil.alertMsg(that,'操作成功')
                    that.submitAlertApplyData=[]
                    //that.totalBatchAlertApplication()
                    that.$router.go(-1)
                    })
                })
            },
            //加项原因列表
            getQueryDictionary(){
                let that=this
                let initData={}
                initData.dictionaryClassName='addItemreason'
                queryDictionary(initData).then(function (response) {
                  responseUtil.dealResponse(that, response, () => {
                    that.addreasonList=response.data.data.data
                  })
                })
            },
            //输入框的公共方法，过滤掉空格
            formatter(value) {
                return value.replace(/\s*/g, '')
            },
		},
  activated() {
    eventBus.$on('referrer', function (data) {
       this.proposer.applyStaff=data.signPersonInfo.id
       this.proposer.applyName=data.signPersonInfo.userName
       this.$set(this.proposer,'applyName',data.signPersonInfo.userName)
       this.$forceUpdate()
    }.bind(this));
    eventBus.$on('decorationChoice',function(data) {
        console.log(data)
        this.application.item_id=data.id
        this.application.itemName=data.itemName
        this.application.goodsallocationmiddlesumId=data.t_goodsallocationmiddlesumId
        this.price=data.price
                this.application.roomHaveSum=data.roomHaveSum
                this.haveAddCount=data.sumCount?data.sumCount:0
                this.getHaveAddCount(data.t_goodsallocationmiddlesumId)
      this.queryGoodsCount(data.id)
    }.bind(this))
  },
  //如果前往的页面不是推荐人，则摧毁缓存
  beforeRouteLeave: function(to, from, next) {
    if (from && to.name != 'referrer'&&to.name!='decorateSearch') {
      //此处判断是如果返回上一层，你可以根据自己的业务更改此处的判断逻辑，酌情决定是否摧毁本层缓存。
      if (this.$vnode && this.$vnode.data.keepAlive) {
        if (
          this.$vnode.parent &&
          this.$vnode.parent.componentInstance &&
          this.$vnode.parent.componentInstance.cache
        ) {
          if (this.$vnode.componentOptions) {
            var key =
              this.$vnode.key == null
                ? this.$vnode.componentOptions.Ctor.cid +
                  (this.$vnode.componentOptions.tag
                    ? `::${this.$vnode.componentOptions.tag}`
                    : '')
                : this.$vnode.key
            var cache = this.$vnode.parent.componentInstance.cache
            var keys = this.$vnode.parent.componentInstance.keys
            if (cache[key]) {
              if (keys.length) {
                var index = keys.indexOf(key)
                if (index > -1) {
                  keys.splice(index, 1)
                }
              }
              delete cache[key]
            }
          }
        }
      }
      this.$destroy()
    }
    next()
  },
        beforeDestroy() {
          eventBus.$off('referrer')
        }

	}
</script>

<style scoped lang="less">
    @redius: 8px;
    .details {
        font-size: 12px;
        font-weight: 900;
        color: #F76243;
        margin-bottom: 5px;
        margin-left: 0px;
    }
    .part-inputpart-row {
       display: flex;
       flex-direction: row;
       border-radius: 8px;
       align-items: center;
       height: 45px;
       background-color: #ffffff;
       margin-top: 15px;
    }
    .part-inputpart-row-vedio {
        position:relative;
       display: flex;
       flex-direction: row;
       border-radius: 8px;
       align-items: center;
       background-color: #ffffff;
       margin-top: 15px;
    }
    .part-inputpart-row-header {
        margin-left: 26px;
        font-weight: 900;
    }
.content-divide {
  font-size: 12px;
  color: #efefef;
  margin: 0 10px;
}
.part-inputpart-row-graytext {
  width: 100px;
  color: #d8d8d8;
  flex: auto;
}
.part-inputpart-row-normaltext {
  color: black;
  flex: auto;
  //font-size: 12px;
}
.part-inputpart-row-right {
  float: right;
  margin-right: 10px;
}

.part-inputpart-row-right-downArrow {
  width: 9px;
}

.part-inputpart-row-right-upArrow {
  width: 9px;
  transform: rotateX(180deg);
}
    .content-have {
        padding: 3px;
        margin: 0 10px;
        border-radius: 50%;
        background-color: #ff5d3b;
    }

    .content-none {
        padding: 3px;
        margin: 0 10px;
        border-radius: 50%;
        background-color: #c7c7c7;
        margin-top: 1px;
    }
.saveButton_Disable {


  background-color: rgba(184, 184, 184, 0.2);
  width: 9rem;
  height: 1.22rem;
  border-radius: 8px;
  margin: 30px 19px;
  font-size: 18px;
  color: white;
  line-height: 1.22rem;
  text-align: center;
}
    .saveButton_Enable {
        background: linear-gradient(to right, #fdc07f 0px, #f86513 100%) repeat scroll 0% 0%;
        color: white;
        width: 345px;
        height: 50px;
        border-radius: 8px;

        font-size: 18px;
        line-height: 1.22rem;
        text-align: center;

    }
    .Button_Enable{
      background: linear-gradient(to right, #fdc07f 0px, #f86513 100%) repeat scroll 0% 0%;
      color: white;
      width: 92%;
      border-radius: 8px;
      font-size: 18px;
      margin-bottom: 10px;
    }
    .closeButton_Enable{
      background-color: #3B98FF;
      color: white;
      width: 92%;
      border-radius: 8px;
      font-size: 18px;
      margin-bottom: 10px;
    }
    .upLoadImg{
        background: white;
        margin:40px 30px 0 30px;
        padding:10px;
    }
    .remake{
        color: gray;
        width: 310px;
        border-radius: 10px;
        line-height: 20px;
        height: 70px;
        margin: 5px 10px 0 10px;
        background-color: #f8f8f8;
        font-size: 12px;
        border: none;
        padding-left: 10px;
        padding-top: 10px;
    }
    .addPropertyDetail{
        width: 92%;
        margin: 0.25rem auto 0.25rem;
        background-color: white;
        border-radius: 0.21333rem;
        padding-bottom: 0.05rem;
        padding-top: 0.05rem;
        /*    备注文本域*/
        .textareaRemarks{
            width: 82%;
            height: 50px;
            margin: 15px auto;
            border-radius: 8px;
            display: block;
            background-color: #f7f7f7;
            border: none;
            font-size: 13px;
            color: #999;
            padding: 15px 15px;
            resize: none;
        }
    }
    .contracts{
        width: 100%;
        overflow: hidden;
        position: relative;
    }
    .contract{
        position: relative;
        margin: 0 15px 15px 15px;
        height: auto;
        background-color: white;
        border-radius: 8px;
        overflow: hidden;
    }
    .top-title-green{
        margin: 0;
        width: 45px;
        height: 20px;
        font-size: 10px;
        text-align: center;
        line-height: 22px;
        border-radius: 8px 0;
        background: linear-gradient(to right,#63cb96,#30d0ac);
        color: white;
    }
    .top-title-red{
        margin: 0;
        width: 45px;
        height: 20px;
        font-size: 10px;
        text-align: center;
        line-height: 22px;
        border-radius: 8px 0;
        background: linear-gradient(to right,#fc401b,#e02321);
        color: white;
    }
    .contract-info{
        width: 100%;
        height: auto;
        padding: 15px 0 10px 0;
        border-bottom: 1px solid #e0e0e0;
    }
    .contract-text{
        height: 25px;
        line-height: 25px;
        text-align: left;
        margin-left: 20px;
        color: #999999;
        font-size: 14px;
    }
    .bottom-changeLease{
        float: right;
        width: 80px;
        height: 30px;
        margin-top: 15px;
        margin-bottom: 15px;
        margin-left: 11px;
        margin-right:11px;
        line-height: 30px;
        background: linear-gradient(to right,#7cc7ff,#3596fd);
        color: white;
        font-size: 12px;
        text-align: center;
        border-radius: 8px;
    }
    .bottom-property{
        float: right;
        width: 90px;
        height: 30px;
        margin-top: 15px;
        margin-left: 11px;
        right: 15px;
        line-height: 30px;
        background: linear-gradient(to right,#ffbe72,#ff6c41);
        color: white;
        font-size: 12px;
        text-align: center;
        border-radius: 8px;
    }
    .position{
        margin: 15px 50px 0 15px;
        height: auto;
        text-align: left;
        font-size: 16px;
        font-weight: bold;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
    }
    .contract-textLong{
        display:inline-block;
        width:100%;
        height: 25px;
        line-height: 25px;
        text-align: left;
        margin-left: 20px;
        color: #999999;
        font-size: 14px;
        white-space:nowrap;
    }
    .potential {
        width: 375px;
        /*background-color: #F8F8F8;*/

        box-sizing: border-box;
        overflow: hidden;
        color: #252525;


        .sub_btn {
            margin: 30px 0 70px;

            .sub_button {
                border-radius: @redius;
                color: rgb(255, 255, 255);
                border: none;
                background: linear-gradient(rgba(255, 194, 116, 1), rgba(255, 93, 59, 1));
                background: -webkit-linear-gradient(left, rgba(255, 194, 116, 1), rgba(255, 93, 59, 1));
                /* Safari 5.1 - 6.0 */
                background: -o-linear-gradient(right, rgba(255, 194, 116, 1), rgba(255, 93, 59, 1));
                /* Opera 11.1 - 12.0 */
                background: -moz-linear-gradient(right, rgba(255, 194, 116, 1), rgba(255, 93, 59, 1));
                /* Firefox 3.6 - 15 */
                background: linear-gradient(to right, rgba(255, 194, 116, 1), rgba(255, 93, 59, 1));

                &.not_choose {
                    background: none;
                    background-color: rgba(184, 184, 184, 0.2);
                }
            }
        }

        p {
            margin: 0px;
        }

        .application_time {
            text-align: right;
            width: 345px;
            font-size: 12px;
            color: rgb(136, 136, 136);
            padding: 2px 0 5px;
        }

        .subtitle {
            padding: 5px 0px;
            color: rgb(244, 99, 76);
            margin-top: 25px;
            font-weight: 700;
            font-size: 12px;
        }

        .overDiv {
            height: 23px;
            /*line-height: 10px;*/
            /*border: 1px solid red;*/
            display: flex;
            align-items: center;
            /*justify-content: center;*/
        }

        /*实际结束更改*/

        .overDiv > div {
            font-weight: 900;
        }

        .overDiv > span {
            font-size: 13px;
            color: rgb(110, 110, 110);
        }

        .calendar_icon {
            display: inline-block;
            width: 18px;
            height: 18px;
            background: url(../../assets/images/timepick.png) no-repeat center center;
            background-size: cover;
            margin-left: 10px;
            background-position-y: -1px;
        }
        .panel {
            width: 100%;
            /*background-color: rgb(255, 255, 255);*/

            position: relative;
            font-size: 14px;
            text-align: left;
            overflow: hidden;
            border-bottom: 1px solid #e0e0e0;

            &.withMargin {
                margin: 15px 0;
            }

            & > p {
                padding: 15px 15px 0px;
                font-weight: 900;
                /*color: #252525;*/

            }

            & > p > span {
                font-weight: normal;
                color: rgb(190, 190, 190);
            }

            .state {
                position: absolute;
                top: 0;
                left: 0;
                font-size: 20px;
                font-weight: 900;
                height: 32px;
                line-height: 32px;
                width: 60px;
                display: block;
                background: linear-gradient(rgba(255, 194, 116, 1), rgba(255, 93, 59, 1));
                background: -webkit-linear-gradient(left, rgba(255, 194, 116, 1), rgba(255, 93, 59, 1));
                /* Safari 5.1 - 6.0 */
                background: -o-linear-gradient(right, rgba(255, 194, 116, 1), rgba(255, 93, 59, 1));
                /* Opera 11.1 - 12.0 */
                background: -moz-linear-gradient(right, rgba(255, 194, 116, 1), rgba(255, 93, 59, 1));
                /* Firefox 3.6 - 15 */
                background: linear-gradient(to right, rgba(255, 194, 116, 1), rgba(255, 93, 59, 1));
                border-top-left-radius: 16px;
                border-bottom-right-radius: 16px;
                color: rgb(255, 255, 255);
                text-align: center;
                transform: scale(0.5);
                transform-origin: 0% 0%;
                font-family: sans-serif;
                padding: 0 10px;
            }

            .title {
                width: 315px;
                padding: 0 15px;
                font-weight: 900;
                color: rgb(0, 0, 0);
                font-size: 16px;
                padding-top: 20px;
                span {
                    font-size: 14px;
                    font-weight: normal;
                    color: rgba(153, 153, 153, 1);
                }
            }

            .allprice {
                padding-bottom: 4px;
            }

            /*审核结果,服务人员 审批意见*/

            .shenhe, .fuwu, .yijian {
                font-size: 15px;
            }

            .describe {
                padding-bottom: 15px;
                padding-top: 10px;
                color: rgb(82, 82, 82);
                font-weight: normal;
            }

            .info {
                padding: 15px;


                &.noPadding {
                    padding: 0 15px;
                }

                p {
                    margin: 4px 0;
                    font-weight: 900;

                    span {
                        font-size: 13px;
                        font-weight: normal;
                        color: rgb(110, 110, 110);
                    }
                }

                .floatR {
                    float: right;
                }

                .total_color {
                    color: rgba(255, 93, 59, 1);
                }
            }

            .text_body {
                padding: 15px;

                .mes_body {
                    border-radius: @redius;
                    background-color: rgb(250, 250, 250);
                }
            }
        }

        .panels {
            width: 100%;
            /*background-color: rgb(255, 255, 255);*/

            position: relative;
            font-size: 14px;
            text-align: left;
            overflow: hidden;


            &.withMargin {
                margin: 15px 0;
            }

            & > p {
                padding: 15px 15px 0px;
                font-weight: 900;
                /*color: #252525;*/

            }

            & > p > span {
                font-weight: normal;
                color: rgb(190, 190, 190);
            }

            .state {
                position: absolute;
                top: 0;
                left: 0;
                font-size: 20px;
                font-weight: 900;
                height: 32px;
                line-height: 32px;
                width: 60px;
                display: block;
                background: linear-gradient(rgba(255, 194, 116, 1), rgba(255, 93, 59, 1));
                background: -webkit-linear-gradient(left, rgba(255, 194, 116, 1), rgba(255, 93, 59, 1));
                /* Safari 5.1 - 6.0 */
                background: -o-linear-gradient(right, rgba(255, 194, 116, 1), rgba(255, 93, 59, 1));
                /* Opera 11.1 - 12.0 */
                background: -moz-linear-gradient(right, rgba(255, 194, 116, 1), rgba(255, 93, 59, 1));
                /* Firefox 3.6 - 15 */
                background: linear-gradient(to right, rgba(255, 194, 116, 1), rgba(255, 93, 59, 1));
                border-top-left-radius: 16px;
                border-bottom-right-radius: 16px;
                color: rgb(255, 255, 255);
                text-align: center;
                transform: scale(0.5);
                transform-origin: 0% 0%;
                font-family: sans-serif;
                padding: 0 10px;
            }

            .title {
                width: 315px;
                padding: 0 15px;
                font-weight: 900;
                color: rgb(0, 0, 0);
                font-size: 16px;
                padding-top: 20px;
                span {
                    font-size: 14px;
                    font-weight: normal;
                    color: rgba(153, 153, 153, 1);
                }
            }

            .allprice {
                padding-bottom: 4px;
            }

            /*审核结果,服务人员 审批意见*/

            .shenhe, .fuwu, .yijian {
                font-size: 15px;
            }

            .describe {
                padding-bottom: 15px;
                padding-top: 10px;
                color: rgb(82, 82, 82);
                font-weight: normal;
            }

            .info {
                padding: 15px;


                &.noPadding {
                    padding: 0 15px;
                }

                p {
                    margin: 4px 0;
                    font-weight: 900;

                    span {
                        font-size: 13px;
                        font-weight: normal;
                        color: rgb(110, 110, 110);
                    }
                }

                .floatR {
                    float: right;
                }

                .total_color {
                    color: rgba(255, 93, 59, 1);
                }
            }

            .text_body {
                padding: 15px;

                .mes_body {
                    border-radius: @redius;
                    background-color: rgb(250, 250, 250);
                }
            }
        }
        .radio_body {
            display: flex;
            justify-content: space-between;
            flex-wrap: nowrap;
            align-items: center;

            .dot {
                display: inline-block;
                width: 7px;
                height: 7px;
                border-radius: 50%;
                background-color: rgb(136, 136, 136);
                vertical-align: middle;
                margin-right: 10px;
                margin-bottom: 6px;

                &.is_dot {
                    background-color: rgb(251, 91, 64);
                }
            }

            .line {
                width: 2px;
                height: 12px;
                background-color: rgb(250, 250, 250);
            }

            .choose {
                margin-right: 20px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                font-size: 15px;

                .choose_btn {
                    padding: 5px;
                    margin: 10px;
                    color: rgb(136, 136, 136);
                    cursor: pointer;

                    &.pass {
                        color: rgb(251, 91, 64);
                        font-weight: 900;
                    }

                    &.reject {
                        color: red;
                        font-weight: 900;
                    }
                }
            }
        }


        .t_table {
            .t_header {
                text-align: center;
                color: rgb(136, 136, 136);
                /*font-weight: 900;*/
                font-size: 10px;
                height: 40px;
                line-height: 40px;
            }

            .t_title {
                background-color: rgb(244, 249, 255);
                color: #33ADFF;
                text-align: center;
                font-weight: 700;
                font-size: 12px;
                height: 20px;
            }

            .t_td {
                text-align: center;
                height: 40px;
                line-height: 40px;
                font-size: 13px;
            }
        }

        .displayBlock {
            //position: relative;
            overflow: hidden;
            background-color: white;
            border-radius: 9px;
            width: 9rem;
            height: auto;
            margin: 19px auto;
        }

        .swiper_show {
            width: 325px;
            position: relative;
            margin: 15px 10px;
            height: 75px;

            .content {
                position: absolute;
                display: flex;
                justify-content: space-around;
                left: 0px;

                .imgWrap {
                    margin-right: 10px;
                }
            }

            /*设计图片部分的css*/

            .part {
                background-color: white;
                border-radius: 8px;
                margin: 15px;

            }


            /*css*/

            .part-subtitle {
                color: #ff5d3b;
                font-size: 12px;
                font-weight: 900;
                position: relative;
                top: 12px;
            }

            /*css*/

            .part-inputpart {
                display: flex;
                flex-direction: column;
                white-space: nowrap;
                overflow: hidden;
                font-size: 15px;
            }


            /*css*/

            .part-uploadpic-module {
                margin: 10px 25px 10px 10px;
            }

            /*css*/

            .part-uploadpic-text {
                font-size: 15px;
                margin-left: 24px;
            }

            /*css*/

            .part-uploadpic-module-pic {
                width: 80px;
                height: 80px;
                border-radius: 8px;
            }


            // 图片墙
            .swiper_show01 { //参数
                width: 9rem;
                position: relative;
                margin: 10px 10px;
                height: 78px;

                .content01 {
                    position: absolute;
                    display: flex;
                    justify-content: space-around;
                    left: 0px;

                    .imgWrap01 {
                        margin-right: 10px;
                    }
                }

                .num01 {
                    position: absolute;
                    left: 8px;
                    top: 15px;
                    font-size: 10px;
                    background-color: rgb(0, 0, 0);
                    opacity: .2;
                    color: rgb(255, 255, 255);
                    padding: 2px 6px;
                    display: inline-block;
                    border-radius: 10px;
                    font-weight: 900;
                    line-height: 10px;
                }
            }

            .displayBlock01 { //参数
                //position: relative;
                overflow: hidden;
                background-color: white;
                border-radius: 9px;
                width: 9rem;
                height: auto;
                margin: 19px auto;
            }


            /*tab部分*/
            // 循环表单留着
            .t_table {
                .t_header {
                    text-align: center;
                    color: rgb(136, 136, 136);
                    font-weight: 900;
                    font-size: 10px;
                    height: 40px;
                    line-height: 40px;
                }

                .t_title {
                    background-color: rgb(244, 249, 255);
                    color: rgb(39, 135, 248);
                    text-align: center;
                    font-weight: 400;
                    font-size: 14px;
                }

                .t_td {
                    text-align: center;
                    height: 40px;
                    line-height: 40px;
                }

                /*tab关联部分css*/

                .fuck {
                    margin: 15px;
                }

                /*css*/

                .part-inputpart {
                    display: flex;
                    flex-direction: column;
                    border-radius: 8px;
                    white-space: nowrap;
                    overflow: hidden;
                    font-size: 15px;
                }

                .part-inputpart-dropDownList-option-selected {
                    color: #ff5d3b;
                }


                /*参数*/

                .part-inputpart-row {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    height: 40px;
                    background-color: #ffffff;
                    margin-top: 2px;
                }

                /*参数*/

                .part-inputpart-row-header {
                    font-weight: 900;
                  //font-size: 14px;
                }


                .part-inputpart-row input {
                    border: 0;
                    //width: 100%;
                    flex: auto;
                }


                /*css参数*/

                .part-inputpart-dropDownList {
                    height: 23%;
                    /*垂直方向滚动*/
                    /*overflow-y: scroll;*/
                    overflow: auto;
                    width: 92.5%;
                    position: absolute;
                    z-index: 99;
                }

                /*css参数*/

                .part-inputpart-dropDownList-option {
                    padding: 0;
                    margin: 0;
                    text-align: center;
                    line-height: 30px;
                    background-color: #f5f5f5;
                    font-size: 12px;
                    border-bottom: 1px white solid;
                    color: #999999;
                }


                /*参数*/

                .part-inputpart-row-enablenon {
                    margin-left: 26px;
                }

                /*参数*/

                .part-inputpart-row-graytext {
                    color: #d8d8d8;
                    //width: 100%;
                    flex: auto;
                }

                .part-inputpart-row-normaltext {
                    color: black;
                    flex: auto;
                }

                .part-inputpart-row-redtext {
                    //width: 100%;
                    flex: auto;
                    color: #ff5d3b;
                }


                /*参数*/

                .part-inputpart-row-right {
                    float: right;
                    margin-right: 10px;
                }

                .part-inputpart-row-right-rightArrow {
                    width: 8px;
                }

                .part-inputpart-row-right-downArrow {
                    width: 9px;
                }

                .part-inputpart-row-right-upArrow {
                    width: 9px;
                    transform: rotateX(180deg);
                }

                .part-inputpart-row-right-timepick {
                    width: 20px;
                }

                .content-none {
                    padding: 3px;
                    margin: 0 10px;
                    border-radius: 50%;
                    background-color: #c7c7c7;
                }

                /*参数*/

                .content-divide {
                    font-size: 12px;
                    color: #efefef;
                    margin: 0 10px;
                }

            }

        }
    }
</style>
